import { combineReducers } from 'redux';
import { READ_DEVOTEES } from '../actions';

const postsReducer = (state = [], action) => {
  switch (action.type) {
    case READ_DEVOTEES: {
      const { data: posts } = action.payload;
      return posts;
    }
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  posts: postsReducer,
});

export default rootReducer;
