import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ReactRouterPropTypes from 'react-router-prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactMarkdown from 'react-markdown';

const terms = `You can choose to remain anonymous. To convert you must connect with a social provider of your choice.

If you decide to connect, your account name will be stored in our database, this information will become public.

You have the right to export your data from the site at any time, subject to reasonable constraints on bandwidth.

You can delete your account at any time by emailing convert@churchofpdf.com.

Your private data will not be intentionally shown to other users or shared with third parties.

Your personal data, email address and identity will not be shared with anyone without your consent.

If the site ceases operation, you will receive an opportunity to download your stored data in a format suitable for import into other accounting services.

You have the right to receive an explanation, and when possible advance warning, if your account is throttled or cancelled for the reasons outlined below.

Any new features that affect privacy will be strictly opt-in.

You will be treated with respect and discretion.

#### Responsibilities

You may not make automated requests to the site more frequently than once every thirty seconds.

You must not abuse the site by knowingly posting malicious code, or links to malicious sites, that could harm other users. 
If you find a security or privacy bug, please report it to convert@churchofpdf.com.

*Last updated 8 October 2019*`;

const privacy = `**Church of PDF adheres to [GDPR](https://www.eugdpr.org/) guidelines.**

We run on Netlify and Firebase. Every page is served through SSL.

We do not share user data with third parties, but we do use google analytics.

If there is a privacy or data breach it will be disclosed as soon as possible.

If you spot a privacy leak, please report it discreetly to convert@churchofpdf.com
 
*Last updated 8 October 2019*`;

const useStyles = makeStyles((theme) => ({
  card: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const Legal = ({ location }) => {
  const classes = useStyles();
  const { pathname } = location;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        { (pathname === '/terms') && (
          <ReactMarkdown source={terms} />
        )}
        { (pathname === '/privacy') && (
          <ReactMarkdown source={privacy} />
        )}
      </div>
    </Container>
  );
}

Legal.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};

export default Legal;
