import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import Home from './views/Home';
import FourOFour from './views/FourOFour';
import Legal from './views/Legal';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/terms" component={Legal} />
    <Route exact path="/privacy" component={Legal} />
    <Route component={FourOFour} status={404} />
  </Switch>
);

export default Routes;
