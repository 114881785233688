import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';

import { devoteesDB, authWrapper } from '../firebase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import TopBar from '../components/TopBar';

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.createDevotee = this.createDevotee.bind(this);
    this.state = {
      authing: false,
      praying: false,
      devoted: false,
    };
  }

  async createDevotee() {
    const {
      user,
    } = this.props;
    if (user) {
      const userData = {
        displayName: user.displayName,
        provider: 'twitter',
        devoted: true,
      };
      this.setState({ praying: true });
      devoteesDB.child(user.uid).set(userData).then(() => {
        this.setState({ praying: false, devoted: true });
      });
    } else {
      this.setState({ authing: true });
    }
  }

  render() {
    const {
      classes,
      user,
      signOut,
      signInWithTwitter,
    } = this.props;
    console.log(user);
    const { authing, praying, devoted } = this.state;

    return (
      <>
        <CssBaseline />
        <TopBar
          user={user}
          signOut={signOut}
          signIn={signInWithTwitter}
        />
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              In a world of dying spirituality
              <span role="img" aria-label="clap">👏</span>
            </Typography>
            <Typography component="h1" variant="h5">
              of too many religions
              <span role="img" aria-label="clap">👏</span>
            </Typography>
            <Typography component="h1" variant="h5">
              of too much uncertainty
              <span role="img" aria-label="clap">👏</span>
            </Typography>
            { authing && (
              <Button onClick={signInWithTwitter}>
                Connect with twitter
              </Button>
            )}
            { devoted ? (
              <Typography component="h1" variant="h2">
                Conversion completed 🖨️
              </Typography>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={this.createDevotee}
                disabled={praying}
              >
                { praying ? ('🙏') : (
                  '🙏 Convert to PDF 🙏'
                )}
              </Button>
            )}
          </div>
        </Container>
      </>
    );
  }
}

Home.propTypes = {
  user: PropTypes.any,
  signOut: PropTypes.func.isRequired,
  signInWithTwitter: PropTypes.func.isRequired,
};

Home.defaultProps = {
  user: null,
};

export default authWrapper(withStyles(styles)(Home));
