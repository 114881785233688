import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TwitterIcon from '@material-ui/icons/Twitter';
import AppBar from '@material-ui/core/AppBar/AppBar';

import { userShape } from '../shapes';

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  twitterLogin: {
    backgroundColor: '#1da1f2',
    color: theme.palette.common.white,
  },
}));

const TopBar = ({ user, signIn, signOut }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          Church of PDF
        </Typography>
        { user ? (
          <>
            <nav>
              <Typography color="inherit">
                {`Welcome ${user.displayName}`}
              </Typography>
            </nav>
            <Button
              color="primary"
              variant="outlined"
              className={classes.link}
              onClick={signOut}
            >
              Logout
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            className={classes.twitterLogin}
            startIcon={<TwitterIcon />}
            onClick={signIn}
          >
            Connect with twitter
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};


TopBar.propTypes = {
  user: userShape,
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

TopBar.defaultProps = {
  user: null,
};

export default TopBar;
