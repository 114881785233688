import * as firebase from 'firebase';
import withFirebaseAuth from 'react-with-firebase-auth';

const config = {
  apiKey: 'AIzaSyAjyc14is29zQO7KgOuJvpkZQN9eqv1Zaw',
  authDomain: 'churchofpdf.firebaseapp.com',
  databaseURL: 'https://churchofpdf.firebaseio.com',
  projectId: 'churchofpdf',
  storageBucket: '',
  messagingSenderId: '683762118309',
  appId: '1:683762118309:web:63eeb8ef230b51e35d274c',
};

const firebaseApp = firebase.initializeApp(config);

const firebaseAppAuth = firebaseApp.auth();
const providers = {
  twitterProvider: new firebase.auth.TwitterAuthProvider(),
};

const databaseRef = firebase.database().ref();

export const devoteesDB = databaseRef.child('devotees');
export const authWrapper = (Component) => withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(Component);
